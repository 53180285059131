.musikk-ikon-gruppe-wrapper{
    position: absolute;
    z-index: 9;

    .musikk-ikon-kategori{
        width:60px;
        height:60px;
        position: absolute;
        cursor:pointer;
        z-index: 2;
    }

    .musikk-ikon-utvalgt{
        position: absolute;
        width: 35px;
        height: 35px;
        cursor: pointer;
        z-index: 2;
    }

    .musikk-ikon-label{
        cursor: pointer;
        position: absolute;
        background:black;
        padding:10px;
        color: #648466;
        font-weight: bold;
        text-transform: uppercase;
        z-index: 1;
        font-size: 20px;
        top: 65px;
        left: 86px;
    }
}

.Orgel-ikon{
    svg{
        path, polygon, polyline{
            fill:#985323;
            stroke:none;
        }
    }
}

.Musikere-ikon{
    svg{
        path, polygon, polyline{
            fill:#985323;
            stroke:none;
        }
    }
}

.Lydsnutter-ikon{
    svg{
        path, polygon, polyline{
            fill:#b76a41;
            stroke:none;
        }
    }
}

.Bakgrunnsstoff-ikon{
    svg{
        path, polygon, polyline{
            fill:#91642f;
            stroke:none;
        }
    }
}

.Noteark-ikon{
    svg{
        path, polygon, polyline{
            fill:#b67a2d;
            stroke:none;
        }
    }
}