.archive-page{
    width:100vw;
    position:absolute;
    padding: 0px 80px;
    color: #648466;
    z-index: 8;
    background-color: black;
    height: 100%;
    overflow-y: scroll;

    .top{
        margin-top: 250px;
        height: 120px;
        display: grid;
        grid-template-columns: auto auto;

        .top-title{
            font-size: 40px;
            line-height: 56px;
            font-weight: bold;
            text-transform: uppercase;
        }
        .top-menu{
            justify-self: end;
            display: grid;
            grid-template-columns: repeat(3, auto);
            align-items: self-start;
            column-gap: 16px;
        }
        .top-menu-button{
            border: thin solid #648466;
            font-size: 23px;
            line-height: 32px;
            font-weight: 600;
            padding: 10px 12px;
            cursor:pointer;
        }
        .top-menu-button:hover{
            color: #fff;
            background:#648466;
        }
        .button-selected{
            color: #fff;
            background:#648466;
        }
        .button-disabled{
            opacity: 0.5;
            pointer-events: none;
        }

        .top-menu-sort-button{
            display: grid;
            grid-template-columns: 20px auto;
            align-items: center;
            cursor:pointer;

            .top-menu-sort-button-circle{
                height: 10px;
                width: 10px;
                border: thin solid #648466;
                border-radius: 100%;
            }
        }

        .top-menu-sort-button:hover{
            .top-menu-sort-button-circle{
                background:#648466;
            }
        }

        .sort-button-selected{
            .top-menu-sort-button-circle{
                background:#648466;
            }
        }
    }
    .top-description{
        margin-bottom: 70px;
        width: 50%;
    }

}

.bigpicture-showing{
    z-index: 10;
}

@media screen and (max-width:1100px){
    .archive-page{
        padding: 0px 10px !important;

        .top{
            margin-top: 150px !important;

        }
        .top-description{
            width: 100% !important;
        }

        .mobile-top-menu{
            justify-self: end;
            display: grid;
            grid-template-columns: repeat(3, auto);
            align-items: self-start;
            column-gap: 16px;
        }
        .mobile-top-menu-button{
            border: thin solid #648466;
            font-size: 23px;
            line-height: 32px;
            font-weight: 600;
            padding: 10px 12px;
            cursor:pointer;
        }
        .button-selected{
            color: #fff;
            background:#648466;
        }
        .accordions-wrapper{
            margin-top: 40px;

            .accordion .header-wrapper .header{
                display: block !important;
                border-bottom: thin solid;

                .arkiv-ikon{
                    width: 100%;
                    margin-bottom: 30px;
                    display: grid;
                    justify-content: center;

                    svg{
                        width: 100px;
                        height: 100px;
                    }
                }

            }

            .accordion-fortelling .header-wrapper .accordion-fortelling-header{
                display:block !important;
                font-size: 24px;
                line-height: 36px;
                border-top: thin solid;

                .accordion-fortelling-dato{
                    display: none;
                }
            }
            .accordion-fortelling .content-wrapper{
                padding-left:0px !important;
                padding-top:20px !important;
            }

            .accordion-fortelling .content-wrapper .accordion-fortelling-bilder{
                margin-top:30px;
                grid-template-columns: repeat(3, 1fr);
            }
        }

        .tegninger-wrapper .content-wrapper{
            grid-template-columns: repeat(3, 1fr) !important;
            padding: 20px 0px !important; 
        }

        .tegninger-wrapper .header{
            border-bottom: none !important;
        }
    }
}

// @media screen and (max-width:700px){

// }