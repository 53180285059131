.accordion {
    width:100%;
    float:left;

    .header-wrapper{
      float:left;
      width:100%;
      display: grid;
      cursor: pointer;
      justify-items: center;
      align-items: center;

      .header{
        width: 100%;
        max-width: 100%;
        position: relative;
        display: grid;
        grid-template-columns: 80px auto;
        padding-top: 30px;
        border-top: thin solid;
        padding-bottom: 30px;
        align-items: center;
        column-gap: 50px;
        font-size: 40px;
        font-weight: bold;

        .accordion-title{
          display: grid;
          align-content: center;
          row-gap: 20px;

          .accordion-text{
            font-size: 17px;
            font-weight: normal;
          }
        }
      }
    }

    .content-wrapper{
      float:left;
      overflow-x: hidden;
      overflow-y: hidden;
      height:auto;
      margin-top:0px;
      width:100%;
      opacity:0;
      transition: height ease 0.2s, opacity 0.2s ease;
    }
    .content-wrapper.open{
      overflow: visible;
      // padding-bottom: 50px;
    }
  }
.accordion.open {
  height: auto;
}

.tegninger-wrapper{
  .content-wrapper{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    padding-left:130px;

    .accordion-bilde-wrapper{
      width:100%;
      cursor: pointer;

      .accordion-bilde{
        transform:scale(1);
        transition:transform 0.1s ease-in-out;
        img{
          max-width: 100%;
        }
      }

      .accordion-bilde:hover{
        transform:scale(1.05);
      }

      .accordion-bilde-caption{
        padding:10px 0px;
      }
    }
  }
}

// @media screen and (max-width:1100px){
//   .accordion .header-wrapper {
//     font-size: 8vw;
//     line-height: 8vw;
//   }

// }
