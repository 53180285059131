.menu-wrapper{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
    padding: 20px 20px 20px 80px;
    font-weight: bold;

    .instagram-button{
        float: right;
        height: 50px;
        width: 50px;
        background: #82a184;
        border-radius: 50%;
        display: grid;
        align-content: center;
        justify-content: center;
        text-align: center;
        color: white;
        font-size: 34px;
        cursor:pointer;
    }
    .instagram-button:hover{
        color: #82a184;
        background: white;
    }

    strong{
        float:left;
        width: 170px;
        display: grid;
        position: relative;
        align-items: center;
        justify-items: center;
        margin-right:20px;
        transform:scale(1) rotate(0deg);
        transition:transform 0.1s ease-in-out;
        cursor: pointer;

        .menu-label{
            position: absolute;
            color:white;
        }

        .meny-sky{
            fill:#82a184;
        }
    }
    strong:hover{
        transform:scale(1.1) rotate(-2deg);
    }

    .zoom-knapp-wrapper{
        position: fixed;
        top:10px;
        right:10px;
        z-index: 9;

        strong{
            display: block;
            width: auto;
            margin-right: 0;
        }

        .zoom-knapp{
            background: white;
            color: black;
            margin: 10px;
            float: left;
            cursor: pointer;
            width: 50px;
            height: 50px;
            text-align: center;
            line-height: 60px;
            border-radius: 50%;
            font-size: 27px;
        }

    }
}

.menu-wrapper-mobile{
    position: fixed;
    transform:translatey(0px);
    transition: transform 0.2s ease-in-out;
    z-index: 9;
    width: 100%;
    height: auto;
    display: grid;

    .menu-instagram-button-mobile{
        opacity: 1;
        transition: opacity 0.2s ease-in-out;

        .instagram-button{
            float: right;
            height: 50px;
            width: 50px;
            background: #82a184;
            border-radius: 50%;
            display: grid;
            align-content: center;
            justify-content: center;
            text-align: center;
            color: white;
            font-size: 34px;
            cursor:pointer;
            margin-right: 20px;
        }
    }

    .ig-button-hidden{
        opacity: 0;
    }

    .menu-content{
        position: relative;
        /* left: 50%; */
        /* transform: translatex(-50%); */
        padding: 20px;
        margin: 0 auto;

        .menu-item{
            max-width: 100%;
            display: block;
            transform: scale(1) rotate(0deg);
            transition: transform 0.1s ease-in-out;
            cursor: pointer;
            position: relative;
            width: 300px;
            margin: 10px 0px;

            .menu-label{
                position: absolute;
                color:white;
                top:50%;
                left:50%;
                transform:translate(-50%, -50%);
            }

            .meny-sky{
                fill:#648466;
            }
        }

        .menu-toggle-button-wrapper{
            height: 50px;
            position: relative;
            background: white;
            display: inline-block;
            color: black;
            width: 50px;
            display: grid;
            align-content: center;
            text-align: center;
            justify-content: center;
            border-radius: 50%;
            left: 50%;
            transform: translatex(-50%);
        }
    }
}

.menu-closed{
    transform:translatey(calc(-100% + 85px));
}
.menu-open{
    transform:translatey(0px);
}

.menu-corner-flower-wrapper{
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 300px;
    height: 300px;
    z-index: 2;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    transform: translate(20px, -70px);

    .menu-corner-flower-text{
        width:100%;
        height:100%;
        display: grid;
        align-content: center;
        justify-content: center;
        z-index: 2;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        color: #648466
    }

    .menu-corner-flower-background{
        width:100%;
        height:100%;
        position:absolute;
        top: 0;
        z-index: -1;
        transform: rotate(0deg);
        animation-name: flowerRotation;
        animation-duration: 30s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        .fl{
            fill:#FFFFFF
        }
    }

}
    @keyframes flowerRotation {
        0%{transform:rotate(0deg)}
        100%{transform:rotate(360deg)}
    }

    .menu-hidden{
        width: 50px !important;
        height: 50px !important;
        transform: scale(1) !important; 
        left: 40px !important;
    
        .menu-corner-flower-background{
            animation-play-state: paused;
        }
    }