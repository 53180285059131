.fødeIkon{
    fill:#4c4ae0;
}
.fødeRamme{
    fill:#4c4ae0;
}
.byggeIkon{
    fill:#a71029;
    // fill:#8f091f;
}
.byggeRamme{
    fill:#a71029;
    //fill:#8f091f;
}
.eieIkon{
    fill:#a19b35;
    // fill:#878339;
}
.eieRamme{
    fill:#a19b35;
    // fill:#878339;
}
.traskeIkon{
    fill:#73b7f8;
}
.traskeRamme{
    fill:#73b7f8;
}
.lekeIkon{
    fill:#1f8955
    // fill:#4da92f
}
.lekeRamme{
    fill:#1f8955
    // fill:#4da92f
}

.musikkIkon{
    fill:#b05b3a;
    // fill:#4da92f
}

// MUSIKKEN

.musikk-ramme{
    width: calc(100% + 100px);
    position: fixed;
    height: calc(100% + 0px);
    top: 50px;
    left: -50px;
    pointer-events: none;
    z-index: 8;

    polygon{
        fill: white;
    }
    path{
        fill: white;
    }
    polyline{
        fill: white;
    }
    // ORGEL

    .orgel-ramme-detalj-top-left-1{
        width: 35px;
        position: absolute;
        top: 120px;
        left: 65px;
        polygon{
            fill: white;
        }
    }
    .orgel-ramme-detalj-top-left-2{
        width: 35px;
        position: absolute;
        top: 56px;
        left: 130px;
        transform: rotate(90deg);
        polygon{
            fill: white;
        }
    }

    .orgel-ramme-detalj-top-right-1{
        width: 35px;
        position: absolute;
        top: 120px;
        right: 65px;
        transform: rotate(180deg);
        polygon{
            fill: white;
        }
    }
    .orgel-ramme-detalj-top-right-2{
        width: 35px;
        position: absolute;
        top: 56px;
        right: 130px;
        transform: rotate(90deg);
        polygon{
            fill: white;
        }
    }

    .orgel-ramme-detalj-bottom-left-1{
        width: 35px;
        position: absolute;
        bottom: 120px;
        left: 65px;
        polygon{
            fill: white;
        }
    }
    .orgel-ramme-detalj-bottom-left-2{
        width: 35px;
        position: absolute;
        bottom: 56px;
        left: 130px;
        transform: rotate(-90deg);
        polygon{
            fill: white;
        }
    }

    .orgel-ramme-detalj-bottom-right-1{
        width: 35px;
        position: absolute;
        bottom: 120px;
        right: 65px;
        transform: rotate(180deg);
        polygon{
            fill: white;
        }
    }
    .orgel-ramme-detalj-bottom-right-2{
        width: 35px;
        position: absolute;
        bottom: 56px;
        right: 130px;
        transform: rotate(-90deg);
        polygon{
            fill: white;
        }
    }

    //LYDSNUTTER

    .lydsnutter-ramme-detalj-top-left{
        width: 70px;
        position: absolute;
        top: 72px;
        left: 140px;
        transform: rotate(180deg);
    }
    .lydsnutter-ramme-detalj-top-middle{
        width: 70px;
        position: absolute;
        top: 72px;
        left: 50%;
        transform: translatex(-50%) rotate(180deg);
    }
    .lydsnutter-ramme-detalj-top-right{
        width: 70px;
        position: absolute;
        top: 72px;
        right: 140px;
        transform: rotate(180deg);
    }

    .lydsnutter-ramme-detalj-bottom-left{
        width: 70px;
        position: absolute;
        bottom: 72px;
        left: 140px;
    }
    .lydsnutter-ramme-detalj-bottom-middle{
        width: 70px;
        position: absolute;
        bottom: 72px;
        left: 50%;
        transform: translatex(-50%);
    }
    .lydsnutter-ramme-detalj-bottom-right{
        width: 70px;
        position: absolute;
        bottom: 72px;
        right: 140px;
    }

    //BAKGRUNNSSTOFF

    .bakgrunnsstoff-ramme-detalj-top-left{
        width: 70px;
        position: absolute;
        top: 68px;
        left: 71px;
    }
    .bakgrunnsstoff-ramme-detalj-top-right{
        width: 70px;
        position: absolute;
        top: 68px;
        right: 71px;
        transform: rotateY(180deg);    
    }
    .bakgrunnsstoff-ramme-detalj-bottom-left{
        width: 70px;
        position: absolute;
        bottom: 68px;
        left: 71px;
        transform: rotateX(180deg)
    }
    .bakgrunnsstoff-ramme-detalj-bottom-right{
        width: 70px;
        position: absolute;
        bottom: 68px;
        right: 71px;
        transform: rotate(180deg);  
    }

    //NOTEARK

    .noteark-ramme-detalj-top-left{
        width: 70px;
        position: absolute;
        top: 68px;
        left: 92.5px;
    }
    .noteark-ramme-detalj-top-right{
        width: 70px;
        position: absolute;
        top: 68px;
        right: 92.5px;
    }
    .noteark-ramme-detalj-bottom-left{
        width: 70px;
        position: absolute;
        bottom: 68px;
        left: 92.5px;
        transform:rotate(180deg)
    }
    .noteark-ramme-detalj-bottom-right{
        width: 70px;
        position: absolute;
        bottom: 68px;
        right: 92.5px;
        transform:rotate(180deg)
    }

    //MUSIKER
    .musiker-ramme-detalj-top-middle{
        width: 100px;
        position: absolute;
        top: 65px;
        left: 50%;
        transform: translatex(-50%);
    }
    .musiker-ramme-detalj-bottom-left{
        width: 70px;
        bottom: 63px;
        position: absolute;
        left: 65px;
    }
    .musiker-ramme-detalj-bottom-right{
        width: 70px;
        bottom: 63px;
        position: absolute;
        right: 65px;
    }
}