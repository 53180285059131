.st0{fill:none;stroke:#648466;stroke-width:0.1;stroke-miterlimit:10;}
.st1{fill:none;stroke:#648466;stroke-width:0.1;stroke-miterlimit:10;}

svg{

    line, polyline{
        fill:none;
        stroke:#648466;
        stroke-miterlimit:10;
    }
}