.fortelling-wrapper{
    position: absolute;
    z-index: 9;
    top:0;
    left:0;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    .ikon-wrapper{
        z-index: 1;
        position:absolute;
        top:0;
        left:0;
        width:50px;
        height:50px;
        // background-color: yellow;
        transform:scale(1);
        cursor: pointer;
        transition: transform 0.1s ease-in-out;
    }
    .ikon-wrapper:hover{
         transform:scale(1.1);
    }

    .fortelling-content-wrapper{
        z-index: 10;
        position: absolute;
        height: 500px;
        width: 380px;
        transform: translate(calc(-50% + 25px), calc(-100% - 20px));

        .fortelling-ramme{
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            pointer-events: none;
        }

        .swiper{
            height: 100%;

            .swiper-button-next{
                color: white;
            }
            .swiper-button-next::after{
                font-size:30px;
            }
            .swiper-button-prev{
                color: white;
            }
            .swiper-button-prev::after{
                font-size:30px;
            }
            .swiper-pagination{
                bottom:10px;
            }
            .swiper-pagination-bullet{
                opacity:0.5;
                background: white;
            }
            .swiper-pagination-bullet-active{
                opacity:1;
            }
        }

        .swiper-text{
            height:440px;
            padding: 50px;
            // width: 280px;
            font-size: 14px;
            line-height: 21px;
            overflow-y: scroll;
            box-sizing: border-box;
            width:100%;

            .swiper-text-title{
                margin-bottom: 20px;
                font-size: 20px;
            }
        }

        .dark{
            color:white;
        }

        .swiper-image{
            // width: 280px;
            box-sizing: border-box;
            width:100%;
            padding: 50px;
            transform:scale(1);
            transition:transform 0.1s ease-in-out;
            cursor:pointer;

            img{
                max-width:100%;
            }
            .swiper-image-caption{
                font-size: 12px;
                transform: translatey(-7px);
            }
        }
        .swiper-image:hover{
            transform:scale(1.05);
        }
    }

    .kat1{
        color:white;
        .fortelling-ramme{
            transform: scale(1.07) translatey(-20px);
        }
    }
    .kat2{
        color:white;
        .fortelling-ramme{
            transform: scale(1.15) translatey(5px);
        }
    }
    .kat3{
        color:white;
        .fortelling-ramme{
            transform: scale(1.22) translatey(38px);
        }
    }
    .kat4{
        .fortelling-ramme{
            transform:scale(1.11) translatey(-18px)
        }
    }
    .kat5{
        color:white;
        .fortelling-ramme{
            transform: scale(1.3) translatey(7px);
        }
    }
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
    opacity: 0;
}
/* Optional: show position indicator in red */
// ::-webkit-scrollbar-thumb {
//     background: #FF0000;
// }