.musikken-page{
    // height:100vh;
    // width:100vw;
    // position:fixed;
    // z-index: 8;
    overflow: hidden;

    .musikken-language-switcher{
        position: fixed;
        top: 30px;
        right: 90px;
        z-index:9;
        // opacity:0;
// transition: opacity 0.2s ease-in;
// transition-delay: 0.5s;
    }
    // .switcher-visible{
    //     opacity:1 !important;
    // }

    .corner-flower-wrapper{
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 220px;
        height: 220px;
        z-index: 2;
        opacity: 1;
        transition: all 0.2s ease-in-out, ;

        .corner-flower-text{
            width:100%;
            height:100%;
            display: grid;
            align-content: center;
            justify-content: center;
            z-index: 2;
            text-align: center;
            font-size: 15px;
            line-height: 20px;
            font-weight: bold;
            color: #648466;

            p{
                width:100px;
                padding-top:10px;
            }
        }

        .corner-flower-background{
            width:100%;
            height:100%;
            position:absolute;
            top: 0;
            z-index: -1;
            transform: rotate(0deg);
            animation-name: flowerRotation;
            animation-duration: 30s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;

            .fl{
                fill:#FFFFFF
            }
        }

        @keyframes flowerRotation {
            0%{transform:rotate(0deg)}
            100%{transform:rotate(360deg)}
            
        }
    }

    .map-scale{
        position:fixed;
        width:100%;
        height:100%;
        transform-origin:center;
        transform:scale(1);
        transition:transform 0.2s ease-in-out;
    }

    @keyframes expanded-fadein {
        0% {opacity:0}
        100% {opacity: 1}
    }

    .expanded-musikk-text-wrapper{
        position:absolute;
        width:calc(100% - 50px);
        height:calc(100vh - 50px);
        top:25px;
        left:25px;
        background:white;
        border-radius:2px;
        padding:50px;
        overflow-y: scroll;
        z-index: 9;
        opacity:0;
        animation: expanded-fadein 0.2s forwards;

        .close-button{
            position: absolute;
            top: 30px;
            right: 30px;
            font-size: 30px;
            transform: scale(1);
            transform-origin: center;
            transition: transform 0.1s ease-in-out;
            cursor: pointer;
        }
        .close-button:hover{
            transform: scale(1.2);
        }
    }

    .expanded-musikk-text-wrapper::-webkit-scrollbar {
        display: none;
    }

    .kirke-intro-wrapper{
        position: absolute;
        width: 50vw;
        top: 6265px;
        left: 5390px;
        transform: scale(1.5);



        .kirke-intro-wrapper-title{
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 60px;
        }

        .kirke-intro-wrapper-text{
            font-size: 17px;
            line-height: 27px;
            margin-bottom: 60px;

            p{
                margin: 15px 0px;
            }
        }
    }

    .map-transform{
        width:7500px;
        height:7500px;
        position: relative;
 
        .fortellinger-wrapper{
            position:absolute;
            top:0;
            left:0;
        }
    }

    .kart-wrapper{
        position: absolute;
        top:0;
        left:0;
        width:2000px;
        height:2000px;
        div{
            width:2000px;
            height:2000px;

            svg{
                width:2000px;
                height:2000px;

                polyline, line {stroke-width: 0.5px !important;}
            }
        }
    }

    .kirkekart-wrapper{
        position: absolute;
        width:2000px;
        z-index: -1;
        cursor: grab;
    }
}


@media screen and (max-width:1100px){
    .musikken-language-switcher{
        top:23px !important; 
        right:8px !important
    }
}

// @media screen and (max-width:700px){

//     .frontpage{

//         .expanded-text-wrapper{
//             padding: 30px;
//             padding-right: 50px;
//             height: calc(100vh - 130px);

//             .close-button{
//                 position: fixed;
//             }

//             .expanded-text-wrapper-title{
//                 font-size: 28px;
//             }
//             .expanded-text-wrapper-main{
//                 -webkit-column-count: 1;
//                 column-count: 1;
//             }
//             .expanded-text-wrapper-credits{
//                 -webkit-column-count: 1;
//                 column-count: 1;
//             }
//         }

//         .intro-wrapper{
//             left: 710px;
//             position: absolute;
//             top: 1120px;
//             width: calc(100vw - 100px);

//             .intro-wrapper-title{
//                 margin-bottom: 55px;
//             }
//             .intro-wrapper-text{
//                 font-size: 18px;
//                 line-height: 28px;
//                 margin-bottom: 60px;
//             }
//         }

//         .corner-flower-wrapper{
//             height: 130px;
//             left: calc(510px + 100vw);
//             opacity: 1;
//             position: absolute;
//             top: calc(840px + 100vh);
//             transition: opacity .2s ease-in-out;
//             width: 130px;
//             z-index: 2;

//             .corner-flower-text{
//                 align-content: center;
//                 display: grid;
//                 font-size: 14px;
//                 font-weight: 700;
//                 height: 100%;
//                 justify-content: center;
//                 line-height: 16px;
//                 text-align: center;
//                 width: 100%;
//                 z-index: 2;
//             }


//         }
//     }

// }