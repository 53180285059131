.bigpicture-wrapper{
    position: fixed;
    width:100%;
    height:100%;
    z-index: 99;
    left:0;

    .bigpicture-close{
        position: fixed;
        right: 20px;
        top: 20px;
        z-index: 9;
        color: white;
        font-size: 50px;
        transform: scale(1);
        transition:transform 0.1s ease-in-out;
        cursor: pointer;
     }
     .bigpicture-close:hover{
        transform: scale(1.2);
     }

    .bigpicture-image-wrapper{
        position: absolute;
        z-index: 2;
        padding: 30px;
        height: 100%;
        width: 100%;
        left: 50%;
        transform: translatex(-50%);

        .bigpicture-image{
            height: 100%;
            object-fit: contain;
            width: 100%;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .bigpicture-caption{
            float:left;
        }
    }

    .bigpicture-background{
        // backdrop-filter: blur(10px);
        position: absolute;
        width:100%;
        height:100%;
        z-index: 1;
        background-color: rgba(0,0,0,0.9);
    }
}