.accordion-fortelling {
  width: 100%;
  height: auto;
  float: left;

    .header-wrapper{
      float:left;
      width:100%;
      display: grid;
      cursor: pointer;
      justify-items: center;
      align-items: center;

      .accordion-fortelling-header{
        width: 100%;
        max-width: 100%;
        position: relative;
        display: grid;
        grid-template-columns: 100px auto auto;
        padding-top: 30px;
        padding-bottom: 30px;
        column-gap: 30px;
        border-top: thin solid;

        .accordion-fortelling-dato{}
        .accordion-title{font-weight: 600;}
        .accordion-les-mer{
          justify-self: end;
          cursor:pointer;
        }
      }
    }

    .content-wrapper{
      float:left;
      overflow-x: hidden;
      overflow-y: hidden;
      height:auto;
      margin-top:0px;
      width:100%;
      padding-left: 130px;
      opacity:0;
      transition: height ease 0.2s, opacity 0.2s ease;

      .accordion-fortelling-tekst{
        width:100%;
        max-width:600px;
        
      }

      .accordion-fortelling-bilder{
        margin-top:100px;
        display:grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 30px;
        row-gap: 30px;
        padding-bottom: 30px;

        .accordion-fortelling-bilde{
          cursor: pointer;
          transform:scale(1);
          transition:transform 0.1s ease-in-out;

          img{
            max-width:100%;
          }
        }

        .accordion-fortelling-bilde:hover{
          transform:scale(1.05);
        }

      }
    }
    .content-wrapper.open{
      overflow: visible;
    }
  
  }
.accordion.open {
  height: auto;
}

@media screen and (max-width:1100px){
  .accordion .header-wrapper {
    font-size: 8vw;
    line-height: 8vw;
  }

}
