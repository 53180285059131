.language-switch-wrapper{
    
    .language-option{
        float:left;
        padding:5px 7px;
        margin:0px 2px;
        cursor:pointer;
        line-height:20px;
        font-weight: bold;
        border-radius: 3px;
    }
    .language-option:hover{
        background:#5e7c60;
        color:white;
    }
    .language-active{
        background:#5e7c60;
        color:white;
    }
}