.zoomlevel{
    position:fixed;
    top:10;
    right:10px;
    color:white;
    z-index: 999;
}
.frontpage{
    overflow: hidden;
    // height:100vh;
    // width:100vw;
    // position:fixed;
    // height: 100%;
    // width: 100vw;
    // position: relative;

    // .menu-bar{
    //     width:100%;
    //     height:auto;
    //     background: ##648466;
    //     padding-left:70px;
    //     height:70px;
    //     display: grid;
    //     align-items: center;
    //     position:absolute;
    //     color:white;
    //     font-weight:bold;

    //     .menu-bar-title{

    //     }
    // }

    
    .intro-wrapper{
        position: absolute;
        width: 640px;
        top: 3020px;
        left: 2630px;
        z-index: 8;

        .language-wrapper{
            position: absolute;
            top:0px;
            right:0px;
        }

        .intro-wrapper-title{
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 40px;
        }

        .intro-wrapper-text{
            font-size: 17px;
            line-height: 27px;
            margin-bottom: 40px;
            float: left;
            cursor: grab;

            p{
                margin-top:10px;
                float: left;
            }
            .intro-p{
                font-size: 25px;
                font-weight: bold;
                margin-bottom: 40px;
                line-height: 33px;
            }
            .split-p{
                // padding: 10px 0px;
                    float: left;
                p{
                    // width: 50%;
                    // float: left;
                    // padding-right: 40px; 
                }
            }

            // p:nth-child(2){
            //     width: 50%;
            //     float: left;
            //     padding-right: 40px;
            // }
            // p:nth-child(3){
            //     width: 50%;
            //     float: left;
            //     padding-right: 40px;
            // }
        }

        .intro-readmore-wrapper{
            float: left;
            background: #5e7c60;
            border-radius: 5px;
            padding: 15px 20px;
            color: #1f2c20;
            font-size: 20px;
            

            .intro-readmore-title-wrapper{
                float: left;
                display: block;
                width: 100%;
                padding-bottom: 9px;

                .intro-readmore-title{
                    font-weight: bold;
                    display: block;
                    color:white;
                    border-top: thin solid #1f2c20;
                    padding: 10px 0px;
                    cursor: pointer;

                    .intro-readmore-title-date{
                        float:right;
                    }
                }
                .intro-readmore-title:hover{
                    color:#1f2c20;
                }
            }

            .intro-readmore-articles{
                border-bottom: thin solid;
                margin-top: 10px;
                margin-bottom: 10px;
            }

        .intro-readmore-button{
            background-color: white;
            padding: 7px 10px;
            font-weight: bold;
            border-radius: 2px;
            color: black;
            display: block;
            cursor: pointer;
            transition: background-color 0.1s ease-in-out;
            margin-right: 15px;
            margin-bottom: 10px;
        }
        .intro-readmore-button:hover{
            background-color:#fff;
            // color:#5e7c60;
            color:#648466
        }
    }
    }

    .placeholder-story{
        background-color: white;
        padding:50px;
        width:300px;
        font-weight:bold;
        position: absolute;
        top: 1690px;
        left: 2250px;
        border-radius: 2px;
    }
    .placeholder-musikk{
        background-color: white;
        padding:50px;
        width:400px;
        font-weight:bold;
        position: absolute;
        top: 1940px;
        left: 1350px;
        border-radius: 2px;
    }

    .corner-flower-wrapper{
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 180px;
        height: 180px;
        z-index: 2;
        opacity: 1;
        transition: all 0.2s ease-in-out, ;

        .corner-flower-text{
            width:100%;
            height:100%;
            display: grid;
            align-content: center;
            justify-content: center;
            z-index: 2;
            text-align: center;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            color: #648466
        }

        .corner-flower-background{
            width:100%;
            height:100%;
            position:absolute;
            top: 0;
            z-index: -1;
            transform: rotate(0deg);
            animation-name: flowerRotation;
            animation-duration: 30s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;

            .fl{
                fill:#FFFFFF
            }
        }

        @keyframes flowerRotation {
            0%{transform:rotate(0deg)}
            100%{transform:rotate(360deg)}
            
        }
    }

    .map-transform{
        width:7500px;
        height:7500px;
        position: relative;
 
        .fortellinger-wrapper{
            position:absolute;
            top:0;
            left:0;
        }
    }
    .transform-wrapper{
        width:100%;
        height: 100%;
        position:fixed;
    }

    .map-scale{
        position:fixed;
        width:100%;
        height:100%;
        transform-origin:center;
        transform:scale(1);
        transition:transform 0.2s ease-in-out;
    }

    .map-smooth{
        transform:auto;
        transition: transform 0.2s ease-in-out;
    }

    .kart-wrapper{
        position: absolute;
        top:0;
        left:0;
        width:7500px;
        height:7500px;
        div{
            width:7500px;
            height:7500px;

            svg{
                width:7500px;
                height:7500px;
            }
        
        }

        // z-index: -1;
        // cursor: grab;
    }
}

@keyframes expanded-fadein {
    0% {opacity:0}
    100% {opacity: 1}
}

.expanded-text-wrapper{
    position:fixed;
    width:calc(100% - 50px);
    height:calc(100vh - 130px);
    top:100px;
    left:25px;
    background:white;
    border-radius:2px;
    // padding:50px;
    overflow-y: scroll;
    z-index: 10;
    opacity:0;
    color: black;
    // display: grid;
    // justify-content: center;
    // animation-name: expanded-fadein;
    // animation-duration: 0.5s;
    // animation-iteration-count: 1;
    animation: expanded-fadein 0.2s forwards;

    .expanded-text-menu{
        position: fixed;
        width:30%;
        padding:30px;

        .meny-item{
            font-weight:bold;
            cursor:pointer;
            color:#5E7C60;
        }
        .meny-item:hover{
            color:#000;
        }
    }

    .expanded-text-content{
        width: 70%;
        position: absolute;
        left: 30%;
        padding-right:20px;

        p{
            margin: 15px 0px;
        }

        a{
            color:black;
        }
        a:hover{
            color:#5E7C60;
            border-bottom: 2px solid;
        }

        .expanded-programme{
            .event-item{
                padding:20px 0px;
    
                .event-item-title{
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 31px;
                }
    
                .event-item-text{
                    max-width:900px;
                }
            }
            .hovedevent{
                border-bottom:#5E7C60 thin solid;
            }
            .underevent{
                padding-bottom:0px;
                .event-item-title{
                    font-weight: normal; 
                }
            }
        }

        .expanded-about{
            .expanded-text-avsnitt-content-subtitle{
                // font-size: 38px;
                font-weight: bold;
                text-transform: uppercase;
                padding: 20px 0px;
                line-height: 40px;
                color:#5E7C60;
                }

            .expanded-text-wrapper-credits{
                // border-top: thin solid #799A7C;
                // margin-top:50px;
                // padding-top:50px;
                // // color:#799A7C;
                // width:100%;
                // column-count: 2;
                // column-gap: 40px;
                // max-width: 1200px;
            }
            .logo-section{
                float: left;
                margin-top: 50px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                grid-gap: 20px;
                width:100%;
        
                .logo{
                    width:350px;
        
                    a{
                        border-bottom:none
                    }
        
                    img{max-width:100%;}
                }
                .oat-logo{
                    width:100px;
                    a{
                        border-bottom:none
                    }
        
                    img{max-width:100%;}
                }
            }
            .expanded-text-wrapper-personvern{
                // border-top: thin solid #000;
                float:left;
                // margin-top:50px;
                padding-top:50px;
                color:black;
                width:100%;
                max-width: 1200px;
                // column-count: 2;
                // column-gap: 40px;
            }
        }

        .expanded-text-avsnitt{
            float:left;
            width:100%;
            max-width: 900px;
            margin-bottom: 40px;
            border-top: thin solid #799A7C;
            padding-top: 40px;

            .avsnitt-video-wrapper{
                margin:50px 0px;
                position: relative;
                padding-bottom: 56.25%; /* 16:9 */
                height: 0;

                iframe{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .avsnitt-image-wrapper{
                width: 100%;
                margin: 50px 0px;

                img{
                    max-width: 100%;
                }
                .avsnitt-image-caption{
                    float:left;
                    width:100%;
                    padding-top:5px;
                }
            }
            .avsnitt-slideshow-wrapper{
                margin: 50px 0px;
                padding-bottom: 30px;
                
                .avsnitt-swiper-image{
                    width: 100%;
                    float:left;
                    height:auto;
                    img{
                        max-width: 100%;
                        float:left;
                    }
                    .avsnitt-swiper-image-caption{
                        float:left;
                        width:100%;
                        height: auto;
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                }

                .swiper-button-prev{
                    color:white;
                    width: 80px;
                    padding: 20px;
                    height: 80px;
                    transform: translate(-20px, -40px);
                }
                .swiper-button-next{
                    color:white;
                    right: 10px;
                    left: auto;
                    width: 80px;
                    padding: 20px;
                    height: 80px;
                    transform: translate(20px, -40px);
                }
                .swiper-pagination{
                    bottom:-5px;
                }
                .swiper-pagination-bullet{
                    background: #5E7C60;
                }
            }

            .expanded-text-avsnitt-tittel{
                font-size: 38px;
                font-weight: bold;
                text-transform: uppercase;
                padding: 20px 0px;
                line-height: 40px;
            }

           
        }
        .expanded-text-avsnitt:last-child{
            margin-bottom:100px;
        }
    }

    strong{
        font-weight: 600;
    }
    p{
        margin:15px 0px
    }

    a{
        color:black;
    }
    a:hover{
        color:#5E7C60;
        border-bottom: 2px solid;
    }
    .language-wrapper{
        position: absolute;
        top: 15px;
        right: 50px;
        z-index:99;
    }

    .close-button{
        z-index:99;
        position: fixed;
        top: 115px;
        right: 40px;
        font-size: 30px;
        transform: scale(1);
        transform-origin: center;
        transition: transform 0.1s ease-in-out;
        cursor: pointer; 
    }
    .close-button:hover{
        transform: scale(1.2);
    }

    .expanded-text-wrapper-title{
        width:100%;
        margin-bottom:50px;
        font-size:40px;
        font-weight:bold;
        max-width: 1200px;
    }
    .expanded-text-wrapper-subtitle{
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 15px;
        max-width: 1200px;
    }

    .expanded-text-wrapper-main{
        width:100%;
        column-count: 2;
        column-gap: 60px;
        max-width: 1200px;
    }

    
}

.expanded-text-wrapper::-webkit-scrollbar {
    display: none;
}

.program-wrapper{
    align-content: start;

    .expanded-text-wrapper-title{
        width: 1200px;
        max-width: calc(100vw - 200px);
    }
    .event-list-wrapper{
        border-top:#5E7C60 thin solid;
    }

    .expanded-text-wrapper-main{
        column-count: 1;

        .event-item{
            padding:20px 0px;

            .event-item-title{
                font-size: 22px;
                font-weight: bold;
                line-height: 31px;
            }

            .event-item-text{
                max-width:900px;
            }
        }
        .hovedevent{
            border-bottom:#5E7C60 thin solid;
        }
        .underevent{
            padding-bottom:0px;
            .event-item-title{
                font-weight: normal; 
            }
        }
        // .event-item:first-child{
        //     border-top:#5E7C60 thin solid;
        // }
    }

}

.hidden{
    width: 50px !important;
    height: 50px !important;
    transform: scale(1);
    cursor:pointer;

    .corner-flower-background{
        animation-play-state: paused;
    }
}

.hidden:hover{
    transform: scale(1.2);
}

.musikk-ikon-wrapper{
    position: absolute;
    width: 60px;
    height: 58px;
    top: 3758px;
    left: 3210px;
    z-index: 9;
    cursor: pointer;
    transform:scale(1);
    transition:transform 0.1s ease-in-out;

    .musikk-ikon-label{
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        color:white;
        font-weight: bold;
    }

    svg{

    }
}

.musikk-ikon-wrapper:hover{
    transform:scale(1.05);
}

.menu-button{
    display: none;
}

@media screen and (max-width:1100px){

    .intro-wrapper{
        width: 100vw !important;
        top: 3020px !important;
        padding: 0px 20px;

        .language-wrapper{
            top: -50px !important;
        }

        .intro-wrapper-title{
            margin-bottom: 25px !important;
        }
        .intro-wrapper-text{
            font-size: 16px !important;
            line-height: 23px !important;
            margin-bottom: 30px !important;

            // p:first-child{
            //     font-size: 21px !important;
            //     line-height: 27px !important;
            //     margin-bottom: 10px !important;
            // }
            // p:nth-child(2){
            //     width: 100% !important;
            //     padding-right: 0px !important;
            // }
            // p:nth-child(3){
            //     width: 100% !important;
            //     padding-right: 0px !important;
            // }
        }
    }

    .expanded-text-wrapper{
        height: calc(100vh - 120px) !important;
        width: calc(100vw - 40px) !important;
        top: 90px !important;
        // top:180px !important;
        left: 20px !important;
        overflow-x:hidden;
        // position: relative;

        .button-background{
            display: block !important;
            position: fixed;
            top: 90px;
            width: calc(100vw - 60px);
            height: 50px;
            background-color: white;
            z-index: 1;
        }


        .close-button{
            top: 100px !important;
            right: 32px !important;
        }

        .menu-button{
            display: block !important;
            position: fixed;
            top: 104px;
            left: 40px;
            z-index: 10;
            font-size: 22px;
            transform: scale(1);
            transform-origin: center;
            transition: transform 0.1s ease-in-out;
            cursor: pointer; 
        }

        .expanded-text-menu {
            position: relative !important;
            width: 100% !important;
            padding: 60px 20px 20px 0px !important;
            // border-bottom: thin solid;
        }

        .expanded-text-content {
            width: 100% !important;
            position: relative !important;
            left: auto !important;
            // padding: 0px 20px !important;
            float: left;
            // padding-top: 40px !important;

            .expanded-text-avsnitt:first-child{

                .expanded-text-avsnitt-tittel{
                    padding-top:60px !important;
                }
            }
        }
    }

    .expanded-text-wrapper{
        padding: 20px !important;

        .logo-section{
            grid-template-columns: 1fr !important;
            justify-items: center;
            grid-gap: 20px;
            grid-template-rows: 1fr auto;

            .logo{
                width:auto !important;
                max-width: 350px;
                margin-bottom:20px;
            }
    
            .oat-logo{
               
            }
        }

        // .close-button{
        //     width: 100% !important;
        //     float: left !important;
        //     margin-bottom: 20px !important;
        //     position: relative !important;
        //     top: auto !important;
        //     right: auto !important;
        // }
        .close-button:hover {
           transform: none !important
        }
        .language-wrapper{
            right: 20px !important;
            position: fixed;
            color: white;
        }

        .expanded-text-wrapper-title{
            font-size: 30px !important;
            font-weight: bold !important;
            float: left !important;
            line-height: 34px;
        }

        .expanded-text-wrapper-main{
            column-count: 1 !important;
            column-gap: 0 !important;
        }
        .expanded-text-wrapper-credits{
            column-count: 1 !important;
            column-gap: 0 !important;
        }
    }
}

// @media screen and (max-width:700px){

//     .frontpage{

//         .expanded-text-wrapper{
//             padding: 30px;
//             padding-right: 50px;
//             height: calc(100vh - 130px);

//             .close-button{
//                 position: fixed;
//             }

//             .expanded-text-wrapper-title{
//                 font-size: 28px;
//             }
//             .expanded-text-wrapper-main{
//                 -webkit-column-count: 1;
//                 column-count: 1;
//             }
//             .expanded-text-wrapper-credits{
//                 -webkit-column-count: 1;
//                 column-count: 1;
//             }
//         }

//         .intro-wrapper{
//             left: 710px;
//             position: absolute;
//             top: 1120px;
//             width: calc(100vw - 100px);

//             .intro-wrapper-title{
//                 margin-bottom: 55px;
//             }
//             .intro-wrapper-text{
//                 font-size: 18px;
//                 line-height: 28px;
//                 margin-bottom: 60px;
//             }
//         }

//         .corner-flower-wrapper{
//             height: 130px;
//             left: calc(510px + 100vw);
//             opacity: 1;
//             position: absolute;
//             top: calc(840px + 100vh);
//             transition: opacity .2s ease-in-out;
//             width: 130px;
//             z-index: 2;

//             .corner-flower-text{
//                 align-content: center;
//                 display: grid;
//                 font-size: 14px;
//                 font-weight: 700;
//                 height: 100%;
//                 justify-content: center;
//                 line-height: 16px;
//                 text-align: center;
//                 width: 100%;
//                 z-index: 2;
//             }


//         }
//     }

// }