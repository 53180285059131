// $rhap_theme-color: #648466;

.musikk-artikkel-wrapper{
    position: fixed;
    background-color: white;
    height: calc(100vh - 200px);
    width: calc(100vw - 100px);
    top: 150px;
    left: 50px;
    z-index: 9;
    overflow-y: scroll;
    color: black;

    .button-background{
        display:none
    }

    .menu-button{
        display: none;
    }

    .close-button{
        position: fixed;
        top: 160px;
        right: 60px;
        z-index: 10;
        font-size: 30px;
        transform: scale(1);
        transform-origin: center;
        transition: transform 0.1s ease-in-out;
        cursor: pointer; 
    }
    .close-button:hover{
        transform: scale(1.2);
    }

    .musikk-artikkel-meny{
        position: fixed;
        width:30%;
        padding:30px;

        .meny-item{
            font-weight:bold;
            cursor:pointer;
            color:#5E7C60;
        }
        .meny-item:hover{
            color:#000;
        }
    }

    .musikk-artikkel-content{
        width: 70%;
        position: absolute;
        left: 30%;
        padding-right:20px;

        p{
            margin: 15px 0px;
        }

        a{
            color:black;
        }
        a:hover{
            color:#5E7C60;
            border-bottom: 2px solid;
        }

        .musikk-artikkel-avsnitt{
            float:left;
            width:100%;
            max-width: 900px;
            margin-bottom: 40px;

            .avsnitt-video-wrapper{
                margin:50px 0px;
                position: relative;
                padding-bottom: 56.25%; /* 16:9 */
                height: 0;

                iframe{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .avsnitt-image-wrapper{
                width: 100%;
                margin: 50px 0px;

                img{
                    max-width: 100%;
                }
                .avsnitt-image-caption{
                    float:left;
                    width:100%;
                    padding-top:5px;
                }
            }
            .avsnitt-slideshow-wrapper{
                margin: 50px 0px;
                padding-bottom: 30px;
                
                .avsnitt-swiper-image{
                    width: 100%;
                    float:left;
                    height:auto;
                    img{
                        max-width: 100%;
                        float:left;
                    }
                    .avsnitt-swiper-image-caption{
                        float:left;
                        width:100%;
                        height: auto;
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                }

                .swiper-button-prev{
                    color:white;
                    width: 80px;
                    padding: 20px;
                    height: 80px;
                    transform: translate(-20px, -40px);
                }
                .swiper-button-next{
                    color:white;
                    right: 10px;
                    left: auto;
                    width: 80px;
                    padding: 20px;
                    height: 80px;
                    transform: translate(20px, -40px);
                }
                .swiper-pagination{
                    bottom:-5px;
                }
                .swiper-pagination-bullet{
                    background: #5E7C60;
                }
            }

            .musikk-artikkel-avsnitt-tittel{
                font-size: 38px;
                font-weight: bold;
                text-transform: uppercase;
                padding: 20px 0px;
                line-height: 40px;
            }

           
        }
        .musikk-artikkel-avsnitt:last-child{
            margin-bottom:100px;
        }
    }
}

@media screen and (max-width:1100px){


    .musikk-artikkel-wrapper{
        height: calc(100vh - 120px) !important;
        width: calc(100vw - 40px) !important;
        top: 90px !important;
        // top:180px !important;
        left: 20px !important;
        overflow-x:hidden;
        // position: relative;

        .button-background{
            display: block !important;
            position: fixed;
            top: 90px;
            width: calc(100vw - 40px);
            height: 50px;
            background-color: white;
            z-index: 1;
        }


        .close-button{
            top: 100px !important;
            right: 32px !important;
        }

        .menu-button{
            display: block !important;
            position: fixed;
            top: 104px;
            left: 40px;
            z-index: 10;
            font-size: 22px;
            transform: scale(1);
            transform-origin: center;
            transition: transform 0.1s ease-in-out;
            cursor: pointer; 
        }

        .musikk-artikkel-meny {
            position: relative !important;
            width: 100% !important;
            padding: 60px 20px 20px 20px !important;
            border-bottom: thin solid;
        }

        .musikk-artikkel-content {
            width: 100% !important;
            position: relative !important;
            left: auto !important;
            padding: 0px 20px !important;
            float: left;
            // padding-top: 40px !important;

            .musikk-artikkel-avsnitt:first-child{

                .musikk-artikkel-avsnitt-tittel{
                    padding-top:60px !important;
                }
            }
        }
    }

    .musikk-ramme {
        display: none !important;
    }
}